.accordion-item {
    margin-bottom: 10px;
}
.accordion-title {
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
    background-color: #007DBC;
    align-items: center;
    border-radius: 10px;
}
.accordion-title-toggle {
    padding-left: 1em;
    padding-right: 1em;
    max-width: 40px;
}
.accordion-title-label {
}
.accordion-title:hover {
    background-color: #007dbc;
}
.accordion-title,
.accordion-content {
    padding: 0.1rem;
}
.accordion-content {
    border: 1px solid #007dbc;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: #c2dbec;
    margin: 0.7rem;
}
