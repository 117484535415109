.tooltip-delivery {
    position: relative;
    display: inline-block;
}

.tooltip-delivery .tooltiptext-delivery {
    border-radius: 10px;
    border: 1px solid #c2dbec;
    background-color: #0b77c2;
    color: white;
    text-align: center;
    padding: 5px;
    visibility: hidden;
    position: absolute;
    width: 100%;
    bottom: 110%;
    left: 50%;
    margin-left: -60px;
    display: inline-block;
}

.tooltip-delivery:hover .tooltiptext-delivery {
    visibility: visible;
}
