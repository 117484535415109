.blockquote-form {
    text-align: start;
    margin-bottom: 15px;
}
/* hidden sidebar for survey wizard */
.survey .wfp--form-wizard__sidebar {
    display: none;
}
.survey .wfp--input-wrapper .wfp--number__controls .wfp--number__control-btn {
    opacity: 1;
}

 