.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    border-radius: 10px;
    border: 1px solid #c2dbec;
    background-color: #0b77c2;
    color: white;
    text-align: center;
    padding: 5px 0;
    visibility: hidden;
    position: absolute;
    width: 120px;
    bottom: 110%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.line {
    height: 100%;
    border-bottom: 2px solid #d4d7d9;
}
.row-totals {
    background-color: #dfe6eb;
    border-top: 3px solid #0b77c2;
}
.wfp--table td {
    border: 1px solid #d3e1e8;
}