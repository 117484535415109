.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #0000;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.add-snack {
    color: #d4d7d9;
}

.add-snack:hover {
    color: #85b7da;
}

.home {
    background-color: #EFF2F5;
}

.icon-home {
    transform: rotate(+10deg);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    fill: #0b77c2 !important;
}


.modal-overlay {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-box {
    display: block;
    background: white;
    width: 80%;
    height: auto;
    padding: 0.5rem;
    /* border-radius: 1rem; */
}

.justify-content-center {
    justify-content: end;
}

.wfp--table th {
    text-align: start;
}

.text-color-blue {
    color: #085387;
}

.min-h-800 {
    min-height: 800px;
}

.center-bottom {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
}

.border-1px-solid {
    border: 1px solid #d4d7d9;
}

.bg-order-item-state-CONFIRMED {
    background-color: #f7b825;
}

.bg-order-item-state-DELIVERED {
    background-color: #c2dbec;
}

.bg-order-item-state-DISCARDED {
    background-color: #d4d7d9;
}

.bg-order-item-state-DONE {
    background-color: #689e18;
}

.bg-order-item-state-LOADED {
    background-color: #85b7da;
}

.bg-order-item-state-PLANNED {
    background-color: #f1c6cb;
}

.bg-order-state-CONFIRMED {
    background-color: #f7b825;
}

.bg-order-state-DELIVERED {
    background-color: #c2dbec;
}

.bg-order-state-DISCARDED {
    background-color: #d4d7d9;
}

.bg-order-state-DONE {
    background-color: #689e18;
}

.bg-order-state-LOADED {
    background-color: #85b7da;
}

.bg-order-state-OVERDUE {
    background-color: #EF404C;
}

.bg-order-state-PLANNED {
    background-color: #f1c6cb;
}

.bg-order-state-REQUESTED {
    background-color: #F47847;
}

.order-item-card-loss {
    border: 2px solid #C5192D;
}

.order-item-card-return-item {
    border: 2px solid yellow;
    background-color: yellow;
    color: black;
}

.text-order-state {
    color: #000000;
}


.text-color-grey {
    color: #85b7da;
}

.line {
    height: 100%;
    border-bottom: 2px solid #d4d7d9;
}
